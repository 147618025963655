<template>

  <PopperModal :popper-id="modalId">
    <template #popperHeader>Event Class</template>
    <template #popperBody>
      <div class="my-3 px-5 text-left">
        <h5 style="font-size: 1.25rem">SMS-H-01
          3.  CLASSIFICATION OF EMERGENCY SITUATION</h5>

        <p style="font-size: 8.65pt">3.1  The Company shall classify all Emergencies into three Classes namely, A, B, C as follows:</p>

        <h6 class="font-weight-bold">Class A:</h6>
        <ul>
          <li>Major accident with a great social impact, involving huge damage to us or affecting a large number of third parties.</li>
          <li>Major accident with widely covered by mass media and requires a prompt response.</li>
          <li>A major accident which requires response measures by the Entire company</li>
        </ul>

        <p>Requires formation of Emergency Response Team (ERT).</p>


        <p>(Examples of emergencies, but not limited to:)</p>
        <ul>
          <li>MARINE POLLUTION INCIDENTS BY THE VESSEL</li>
          <li>SERIOUS MARINE CASUALTIES (FIRE, COLLISION, STRANDING/GROUNDING, FLOODING/SINKING, SERIOUS</li>
          <li>TERRORISM SUCH AS HIJACKING AND PIRACY</li>
          <li>OTHER CASUALTIES WHICH MAY HAVE A SERIOUS DAMAGE ON THE LIFE, VESSEL, CARGO AND ENVIRONMENT, INVOLVING LARGE NUMBER OF THIRD PARTIES, HAVING SIGNIFICANT SOCIAL IMPACT AND WIDELY COVERED BY MASS MEDIA</li>
        </ul>


        <h6 class="font-weight-bold">Class B:</h6>
        <p>
          Accidents with medium impact, medium social impact and coverage by the Media and not having potential of expanding. <br>
          Requires formation of ERT.
        </p>

        <p>(Examples,but not limited to):</p>
        <ul>
          <li>Accident like loss of propulsion ,which requires assistance of an ocean tug resulting from main engine or auxiliaries failure, etc.</li>
          <li>Collision with another vessel (serious hull damage without oil spills)</li>
          <li>Minor oil spill incident (as result of other than collision and grounding)</li>
          <li>Major cargo damage (including fire and hull damage but excluding oil spill)</li>
          <li>Cyber attack resulting in loss of communication, or seriously damaging navigable capabilities of the vessel.</li>
        </ul>

        <h6 class="font-weight-bold">Class C:</h6>
        <p>
          Accidents other than Category A and B. <br>
          Does not require formation of ERT, can be handled by the Person in charge.
        </p>
      </div>
    </template>
    <template #popperFooter>
    </template>
  </PopperModal>

</template>

<script>


import {defineComponent} from "vue";
import PopperModal from "@/components/elements/PopperModal.vue";

export default defineComponent({
  name: 'EventClassHelpMenuModal',
  components: {PopperModal},
  props: {
    modalId: String
  }
})
</script>
